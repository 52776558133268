<template>
    <s-form @submit="onSubmit">
        <validated-input v-model="model.oldPassword" type="password" label="Old Password"/>
        <validated-input v-model="model.newPassword" type="password" label="New Password"/>

        <btn type="submit" color="success" text="Save"/>
    </s-form>
</template>

<script>
import axios from 'secure-axios';
import urls from '@/data/urls';

export default {
    name: 'ChangePassword',
    props: {
        user_id: {
            type: String,
            required: true
        }
    },
    components: {},
    data () {
        return {
            model: {
                id: this.user_id,
                oldPassword: '',
                newPassword: ''
            }
        };
    },
    methods: {
        onSubmit () {
            const component = this;
            axios.form(urls.auth.ChangePassword, component.model).then(function (data) {
                const json = data.data;
                if (json.Success) {
                    component.$emit('success');
                }
            });
        }
    }
};
</script>

<style scoped>

</style>
